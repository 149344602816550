import $ from "jquery";

let scrollpos;

export default class Header {
  constructor() {
    this.toggleModal();
    this.toggleBgColor();
    this.toggleNews();
    this.headerThinly();
  }

  toggleModal() {
    const $toggleBtn = $(".jsc-menu-btn");
    const $toggleBtnCross = $(".jsc-menu-btn-cross");
    const $toggleBtnText = $(".jsc-menu-btn-text");
    const $headerSpMenu = $(".jsc-toggle-menu");
    $toggleBtn.on("click", () => {
      $headerSpMenu.stop(true, false).slideToggle();
      $toggleBtnCross.toggleClass("is-open");
      if ($toggleBtnText.text() === "MENU") {
        $toggleBtnText.stop(true, false).text("CLOSE");
        document.getElementsByTagName('body')[0].style.top = -scrollpos;
        document.getElementsByTagName('body')[0].style.overflow = 'hidden';
        document.getElementsByTagName('html')[0].style.overflow = 'hidden';
      } else {
        $toggleBtnText.stop(true, false).text("MENU");
        document.getElementsByTagName('body')[0].style.overflow = 'initial';
        document.getElementsByTagName('html')[0].style.overflow = 'initial';
        window.scrollTo(0, scrollpos);
      }
    });
  }

  toggleBgColor() {
    const $header = $("#jsi-header");
    $(window).on("scroll", () => {
      const $headerPosition = $header.offset().top;
      if ($headerPosition > 0) {
        $header.addClass("is-coloring");
      } else {
        $header.removeClass("is-coloring");
      }
    });
  }

  toggleNews() {
    const $headerNews = $(".jsc-header-news");
    // let scroll = 0;
    // $(window).on("scroll", function () {
    //   if ($(this).scrollTop() < scroll) {
    //     $headerNews.slideDown();
    //   } else {
    //     $headerNews.slideUp();
    //   }
    //   scroll = $(this).scrollTop();
    // });
    let start_position = 0, //初期位置０
      window_position,
      $window = $(window);

    // スクロールイベントを設定
    $window.on("scroll", function () {
      window_position = $(this).scrollTop();
      if (window_position <= start_position) {
        $headerNews.slideDown();
      } else {
        if (window_position >= 10) {
          $headerNews.slideUp();
        }
      }
      start_position = window_position;
    });
    $window.trigger("scroll");
  }

  headerThinly() {
    const $header = $("#jsi-header");
    const $headerRemoveElem = $(".jsc-header-remove-elem");
    $(window).on("scroll", () => {
      const $headerPosition = $header.offset().top;
      if ($headerPosition > 0) {
        $headerRemoveElem.slideUp();
      } else {
        $headerRemoveElem.slideDown();
      }
    });
  }
}
