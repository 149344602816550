import Accordion from "./Accordion";
import Scroll from "./Scroll";
import Slider from "./Slider";
import Header from "./header";
// import Home from '../home'
export default class Router {
  constructor() {
    this.model = {
      pathName: location.pathname,
    };

    this.initRouting();
  }

  initRouting() {
    const pathName = this.model.pathName;
    switch (pathName) {
      case "/":
        new Accordion();
        new Scroll();
        new Slider();
        new Header();
        break;
      default:
        new Accordion();
        new Scroll();
        new Slider();
        new Header();
        break;
    }
  }
}
