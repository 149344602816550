import $ from "jquery";
export default class Scroll {
  constructor() {
    this.smoothScroll();
  }
  //ページ内リンクスムーススクロール（Header分を加味した高さに）
  smoothScroll() {
    const $inPageLink = $('a[href^="#"]');
    $inPageLink.on("click", (e) => {
      const targetId = $(e.currentTarget).attr("href");
      const adjustHeaderHeight = $("#jsi-header").innerHeight();
      const targetPosition = $(targetId).offset().top - adjustHeaderHeight;
      const scrollSpeed = 400;
      $("body,html").animate(
        { scrollTop: targetPosition },
        scrollSpeed,
        "swing"
      );
    });
  }
}
