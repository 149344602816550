import $ from "jquery";
export default class Accordion {
  constructor() {
    this.toggleAccordion();
  }
  toggleAccordion() {
    const $accordion = $(".jsc-accordion");
    const $accordionToggleElem = $(".jsc-accordion-toggle");
    $accordion.on("click", (e) => {
      $(e.currentTarget)
        .find($accordionToggleElem)
        .stop(true, false)
        .slideToggle();
      $(e.currentTarget).find($(".faq-question")).toggleClass("is-open");
    });
  }
}
