import $ from "jquery";
import Swiper from "swiper/bundle";
import "swiper/css";

export default class Slider {
  constructor() {
    this.voiceSlider();
    this.aboutSlider();
  }
  voiceSlider() {
    const swiper = new Swiper(".jsc-voice-slider", {
      speed: 7000,
      loop: true,
      slidesPerView: "auto",
      allowTouchMove: false,
      autoplay: {
        delay: 0,
        disableOnInteraction: false,
      },
      breakpoints: {
        //breakpoints
        320: {
          spaceBetween: 24,
          centeredSlides: true,
        },
        1040: {
          spaceBetween: 64,
          autoplay: {
            delay: 0,
          },
        },
      },
    });
  }

  aboutSlider() {
    let currentIndex = 0;
    const $slide = $(".jsc-slider-item");
    //initialize
    $slide.css("display", "none");
    $slide.eq(currentIndex).removeClass("u-hidden").css("display", "block");
    const changeSlide = () => {
      if (currentIndex === 2) {
        currentIndex = 0;
        $slide.fadeOut(1500);
        $slide.eq(currentIndex).fadeIn(1500);
      } else {
        currentIndex++;
        $slide.fadeOut(1500);
        $slide.eq(currentIndex).fadeIn(1500);
      }
    };
    setInterval(changeSlide, 6000);
  }
}
